import {
  CalendarIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BsPlusSquare,
} from "react-icons/bs";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getAllForms } from "../../../services/form.service";
import { useQuery } from "@tanstack/react-query";
const ListeDesFormulaires = () => {
  const location = useLocation();
  const isCreatingForm = location.pathname.includes("create-form");

  const [filter, setFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedForms, setSelectedForms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const { state } = location;

  const getAllformms = async () => {
    try {
      const response = await getAllForms({
        page: page + 1,
        per_page: rowsPerPage,
        searchTerm: searchQuery,
        status: filter,
      });
      setTotalRows(response?.total);
      return response?.data;
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["listForms", searchQuery, page, rowsPerPage, filter],
    queryFn: getAllformms,
  });

  useEffect(() => {
    if (state?.formCreated) {
      refetch();
    }
  }, [state, refetch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCheckboxChange = (formId) => {
    setSelectedForms((prevSelected) =>
      prevSelected.includes(formId)
        ? prevSelected.filter((id) => id !== formId)
        : [...prevSelected, formId]
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  if (isCreatingForm) {
    return <Outlet />;
  }
  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des formulaires</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-2">
        <Link to="create-form" className="no-underline">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium no-underline"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer un nouveau formulaire
          </Button>
        </Link>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              onChange={handleSearchChange}
              value={searchQuery}
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par titre "
              required
            />
          </div>
          <div className="flex items-center space-x-4">
            {/* <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50"
            >
              <BsCalendar className="mr-2" />
              Date
            </Button> */}
            {/* <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50"
            >
              <BsFilter className="mr-2" />
              Statut
            </Button> */}
            <FormControl variant="outlined" sx={{ width: 150 }}>
              <Select
                value={filter}
                onChange={handleFilter}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                )}
                renderValue={(selected) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <i className="bi bi-filter  text-xl mx-1"></i>
                    <span style={{ flex: 1, textAlign: "center" }}>
                      {selected || <>Statut</>}
                    </span>
                  </div>
                )}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value="">
                  <>Statut</>
                </MenuItem>
                <MenuItem value="Publié">Publié</MenuItem>
                <MenuItem value="Brouillon">Brouillon</MenuItem>
              </Select>
            </FormControl>
            {/* <Button
              style={{ textTransform: "none" }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm"
            >
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button> */}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedForms?.length > 0 &&
                      selectedForms?.length < data.length
                    }
                    checked={
                      data?.length > 0 && selectedForms?.length === data?.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedForms(data.map((form) => form.id));
                      } else {
                        setSelectedForms([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Titre formulaire</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Dernière modification</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <div className="p-4 justify-center items-center">
                  <CircularProgress />
                </div>
              ) : (
                data?.map((form) => (
                  <TableRow
                    key={form.id}
                    hover
                    selected={selectedForms.includes(form.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedForms.includes(form.id)}
                        onChange={() => handleCheckboxChange(form.id)}
                      />
                    </TableCell>
                    <TableCell>{form?.title || "Aucun titre trouvé"}</TableCell>
                    <TableCell>
                      <p
                        className={` m-0 ${
                          form?.status === "Publié"
                            ? "text-green-500"
                            : "text-yellow-500"
                        }`}
                      >
                        {form?.status}
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5  text-gray-600" />
                        </span>
                        {formatDate(form.createdAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(form.updatedAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/formulaires/${form.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                        style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                      >
                        Voir détails
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            // labelRowsPerPage="Lignes par page"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Outlet />
      </div>
    </div>
  );
};

export default ListeDesFormulaires;
