import React from 'react'

const ResponsesEvaluateurs = ({arrayOfActions}) => {

    const evaluateursFeedback = arrayOfActions?.filter(
        (item) => !item?.isSystemEvaluation
      );

  return (
    <div>
        <div className="flex gap-2 flex-col">
        {evaluateursFeedback &&
          evaluateursFeedback?.length > 0 &&
          evaluateursFeedback?.map((evaliateurFeedback, index) => {
            return (
              <div className="flex flex-col border-2 border-[#E2F1FA]  rounded-lg p-1">
                <h5>Evaluation n° {index + 1 }:</h5>
                <p><span className="font-semibold">Nom évaluateur:</span> {evaliateurFeedback?.evaluatorUserData?.firstName} {evaliateurFeedback?.evaluatorUserData?.lastName} </p>
                <p><span className="font-semibold">Email évaluateur:</span> {evaliateurFeedback?.evaluatorUserData?.email}</p>
                <p className="">
                <span className="font-semibold"> Status :</span>{" "}
                  <span
                    className={` ${
                      evaliateurFeedback?.status === "Rejeté"
                        ? "text-[#EE5D50]"
                        : "text-[#05CD99]"
                    } `}
                  >
                    {evaliateurFeedback?.status}
                  </span>
                </p>
                <p><span className="font-semibold">Commentaire : </span>{evaliateurFeedback?.evaluatorComment}</p>
              </div>
            );
          })}
      </div>
    </div>
  )
}

export default ResponsesEvaluateurs