import React from "react";
import logo from "../../assets/logo1.png";
import clockIcon from "../../assets/clockIcon.png";
import FaClock from "../../assets/Icon.png";
import mailIcon from "../../assets/mailIcon.png";
import positionIcon from "../../assets/positionIcon.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#F6F6F6] py-8">
      <div className="container mx-auto ">
        <div className="mb-4 md:text-left">
          <Link to="https://www.transforme.cd/" target="_blank">
            <img src={logo} className="w-[40%] md:w-[20%] md:mx-0" alt="Logo" />
          </Link>
        </div>
        <div className="flex flex-col md:flex-row mt-[15px] gap-6 text-sm">
          <div className="flex-1 px-3">
            <h5 className="md:text-left">Notre Plateforme</h5>
            <p className="text-[#808080] md:text-left">
              COPA Transforme est une plateforme dédiée à soutenir les
              entrepreneurs en leur offrant des outils, des formations et un
              accompagnement personnalisé pour propulser leurs projets et
              stimuler leur croissance.
            </p>
          </div>
          <div className="flex-1 px-1">
            <h5 className="md:text-left font-bold">Contacts</h5>
            <div className="text-[#808080] md:text-left">
              <div className="flex items-center mb-2 gap-2">
                <img
                  src={clockIcon}
                  className="mr-1 text-lg w-[15px] h-[15px] text-[#0976BC]"
                  alt="clockIcon"
                />
                <span>Lun. - Ven. : 08h30 - 17h00</span>
              </div>
              <div className="flex mb-2 gap-2">
                <img
                  src={positionIcon}
                  className="mr-2 text-lg text-[#0976BC] w-[18px] h-[18px] "
                  alt="positionIcon"
                />
                <span>
                  9-11, Croisement des avenues Kisangani et Lemarinel, 2ème
                  étage, Immeuble SOFIDE (en face du Collège Boboto) | Code
                  postal 01209 | Kinshasa - Gombe | RDC
                </span>
              </div>
              <div className="flex items-start  mb-2 gap-2">
                <img
                  src={FaClock}
                  className="mr-0 text-lg text-[#0976BC] w-[20px] h-[20px]"
                  alt="FaClock"
                />
                <div className="flex flex-col">
                  <span>Goma : +243 975 694 577</span>
                  <span>Bukavu : +243 859 789 377</span>
                  <span>Bunia : +243 993 672 342</span>
                  <span>Mbujimayi : +243 856 801 297</span>
                  <span>
                    Kinshasa : +243 828 644 241 / <br />
                    +243 840 175 185
                  </span>
                  <span>Matadi : +243 833 591 149</span>
                  <span>
                    Kasangulu, Kisantu, Mbanza-Ngungu :<br /> +243 857 796 560
                  </span>
                  <span>
                    Kimpese, Boma, Muanda :<br /> +243 857 796 560
                  </span>
                  <span>
                    Kananga : +243 837 507 408
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-2 gap-2">
                <img
                  src={mailIcon}
                  className="mr-1 text-lg text-[#0976BC] w-[15px] h-[15px] ms-1"
                  alt="mailIcon"
                />
                <span>support@copa.transforme.cd</span>
              </div>
            </div>
          </div>
          <div className="flex-1 px-3">
            <h5 className="md:text-left">À propos</h5>
            <p className="text-[#808080] md:text-left">Valeurs</p>
            <p className="text-[#808080] md:text-left">Missions</p>
            <p className="text-[#808080] md:text-left">Partenaires</p>
          </div>
          {/* <div className="flex-1">
            <h5 className="md:text-left">Plus</h5>
            <p className="text-[#808080] md:text-left">Programmes</p>
            <p className="text-[#808080] md:text-left">
              Processus de Sélection
            </p>
            <p className="text-[#808080] md:text-left">Témoignages</p>
            <p className="text-[#808080] md:text-left">Contact</p>
          </div> */}

          <div className="flex-1 px-3">
            <h5 className="md:text-left">Réseaux Sociaux</h5>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-linkedin text-[#808080] mr-2"></i>
                <Link
                  to="https://www.linkedin.com/showcase/projet-transforme-rdc/"
                  target="_blank"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                >
                  LinkedIn
                </Link>
              </span>
            </p>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-instagram text-[#808080] mr-2"></i>
                <Link
                  to="https://www.instagram.com/rdctransforme/"
                  target="_blank"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                >
                  Instagram
                </Link>
              </span>
            </p>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-twitter text-[#808080] mr-2"></i>
                <Link
                  to="https://twitter.com/RdcTransforme"
                  target="_blank"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                >
                  Twitter
                </Link>
              </span>
            </p>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-facebook text-[#808080] mr-2"></i>
                <Link
                  to="https://facebook.com/rdctransforme"
                  target="_blank"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                >
                  Facebook
                </Link>
              </span>
            </p>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-tiktok text-[#808080] mr-2"></i>
                <Link
                  to="https://www.tiktok.com/@projettransformerdc"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                  target="_blank"
                >
                  Tiktok
                </Link>
              </span>
            </p>
            <p className="text-[#808080] md:text-left">
              <span className="inline-flex items-center">
                <i className="bi bi-youtube text-[#808080] mr-2"></i>
                <Link
                  to="https://www.youtube.com/channel/UC1BwSinfI5PsZoWIoE3mOvg"
                  className="text-[#808080] no-underline hover:scale-110 hover:-translate-y-[0.5px]"
                  target="_blank"
                >
                  YouTube
                </Link>
              </span>
            </p>
          </div>
        </div>
        <hr></hr>
        <div className="flex justify-center items-center text-[#808080] text-[15px]">
          <p>
            Copyright ©{new Date().getFullYear()} -{" "}
            <span className="font-bold text-[#2F2F2F]">COPA TRANSFORME </span> -
            Tous droits réservés
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
