import React from "react";
import image1 from "../../assets/actualites1.png";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { BsFillPinFill } from "react-icons/bs";
import { calculateReadingTime } from "../../features/ReadingTimeFunction";
import { Carousel } from "react-responsive-carousel";

const ActualitesCard = ({ actualites, loading }) => {
  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const isVideo = (url) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    return videoExtensions.some((ext) => url.endsWith(ext));
  };

  const renderThumbs = (mediaItems) =>
    mediaItems.map((mediaItem, idx) =>
      isVideo(mediaItem.url) ? (
        <video
          src={mediaItem.url}
          muted
          className="w-full h-16 object-cover"
          key={idx}
        />
      ) : (
        <img
          src={mediaItem.url || image1}
          alt="thumb"
          className="w-full h-16 object-cover"
          key={idx}
          onError={(e) => { e.target.src = image1; }} // Fallback to default image if the thumbnail fails to load
        />
      )
    );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      {actualites?.map((actualite, index) => (
        <div
          key={index}
          className="relative shadow rounded overflow-hidden hover:bg-slate-50 border border-gray-400 "
        >
          <div className="relative">
            <Carousel
              useKeyboardArrows
              dynamicHeight={false}
              className="overflow-hidden"
              showThumbs={true}
              renderThumbs={() =>
                renderThumbs(
                  actualite.media?.length ? actualite.media : [{ url: image1 }]
                )
              }
            >
              {actualite.media?.length > 0 &&
              actualite.media.some((mediaItem) =>
                mediaItem.url.includes(
                  "news-articles"
                )
              ) ? (
                actualite.media.map((mediaItem, idx) => (
                  <div key={idx} className="h-[300px]">
                    {isVideo(mediaItem.url) ? (
                      <video
                        src={mediaItem.url}
                        muted
                        autoPlay
                        loop
                        playsInline
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={mediaItem.url}
                        alt="Actualite"
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="h-[300px]">
                  <img
                    src={image1}
                    alt="Default Actualite"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </Carousel>

            {actualite.isPinned && (
              <BsFillPinFill
                className="absolute top-4 right-4 text-green-600 text-2xl"
                title="Épinglé"
              />
            )}

            <div className="absolute bottom-24 left-4 flex gap-2">
              {actualite.categories?.map((category, i) => (
                <span
                  key={i}
                  className="flex items-center text-center bg-white text-gray-800 text-xs rounded-full px-2 py-1 shadow-md"
                >
                  {category}
                </span>
              ))}
            </div>
          </div>

          <div className=" mt-0 px-3 pb-10 relative">
            <div className="flex justify-between border-b-2">
              <p className="text-gray-500 text-xs mb-2">
                {new Date(actualite.created_at).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <p className="text-gray-500 text-xs mb-2">
                {calculateReadingTime(actualite?.description || "3")} minutes de
                lecture
              </p>
            </div>
            <h3 className="text-lg font-semibold my-2 ">{actualite.title}</h3>
            <p className="text-gray-700 text-sm mb-4 description-card">
              <p
                className="text-sm lg:text-sm text-gray-700 line-clamp-3 "
                dangerouslySetInnerHTML={{
                  __html: actualite?.description || "N/A",
                }}
              ></p>
            </p>
          </div>
          <Link
            to={`/actualite/details/${actualite?.id}`}
            className="text-black no-underline  text-xs w-fit bg-yellow-400 hover:bg-yellow-500 p-2 absolute bottom-2 right-2   rounded"
          >
            Voir plus
            <i class="bi bi-arrow-right ml-2"></i>
          </Link>
        </div>
      ))}
    </div>
  );
};


export default ActualitesCard;
