const PdfPreview = ({ pdfUrl }) => {
  const enterFullScreen = () => {
    const iframe = document.getElementById("pdfIframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };
  const ensureHttps = (url) => {
    if (!url?.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const finalUrl = ensureHttps(pdfUrl);

  return (
    <div className="relative w-full">
      <iframe
        id="pdfIframe"
        src={finalUrl + "#toolbar=0"}
        title="PDF Document"
        width="100%"
        height="300px"
        className="border border-sky-500 rounded"
      ></iframe>

      <button
        type="button"
        onClick={enterFullScreen}
        className="absolute top-2 right-2 bg-sky-500 text-white py-2 px-4 rounded shadow-md hover:bg-sky-600 transition-all text-sm"
      >
        plein écran
      </button>

      <div className="flex justify-between items-start mb-4 m-auto">
        <div className="flex flex-col space-y-2 ml-4 p-2">
          <div className="flex space-x-4 items-center">
            <a
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sky-600 hover:text-sky-800 font-semibold"
            >
              Télécharger le document
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfPreview;
