import React, { useEffect, useState } from "react";
import ProgramCard from "../../components/cards/ProgramCard";
import { CircularProgress } from "@mui/material"; 
import { getAllPrograms } from "../../services/program.service";

const CandidatureOffers = ({ searchQuery }) => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const getLatestPrograms = async () => {
      try {
        const data = await getAllPrograms();


        const publishedPrograms = data?.data?.filter((program) => program.status === "Publié");

        setPrograms(publishedPrograms);
      } catch (error) {
        console.error("Error fetching programs:", error);
      } finally {
        setLoading(false); 
      }
    };

    getLatestPrograms();
  }, []);

  const filteredPrograms = programs.filter((program) =>
    program.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container my-10 items-center bg-center justify-center flex flex-col gap-3">
      {loading ? ( 
        <CircularProgress />
      ) : filteredPrograms.length > 0 ? (
        filteredPrograms.map((program) => (
          <ProgramCard
            id={program.id}
            category={program.categories}
            title={program.title}
            description={program.description}
            key={program.id}
            ddl={program?.expiresIn}
            img={program?.image?.url}
          />
        ))
      ) : (
        <p>Restez à l'écoute pour découvrir nos prochains appels à candidature.</p>
      )}
    </div>
  );
};

export default CandidatureOffers;
