import React from "react";
import PdfPreview from "../../components/pdfPreview/PdfPreview";

const DocsSigné = ({ pdfUrl, onClose }) => {
   

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 h-2/3 md:h-auto overflow-y-auto flex flex-col">
          <PdfPreview  pdfUrl={pdfUrl} />
              

                <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700 mt-6 text-center"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
};

export default DocsSigné;
