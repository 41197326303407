import React, { useContext } from "react";
import logo from "../../assets/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ActiveMenuContext } from "../../contexts/MenuContext";
import DashboardIcon from "../../assets/DashboardIcon.svg";
import AppelIcon from "../../assets/AppelCandidatureIcon.svg";
import reglageIcon from "../../assets/reglageIcon.svg";
import coachsIcon from "../../assets/coachsIcon.svg";
import listForm from "../../assets/listForm.svg";
import userListe from "../../assets/user-liste.svg";
import liste from "../../assets/liste.svg";
import Actualites from "../../assets/actualitesIcon.svg";
import ActualitesActive from "../../assets/actualitesActiveIcon.svg";
import listFormActive from "../../assets/icons/activeIcon/listFormActive.svg";
import reglageIconActive from "../../assets/icons/activeIcon/reglageIconActive.svg";
import AppelIconActive from "../../assets/icons/activeIcon/AppelIconActive.svg";
import coachsIconActice from "../../assets/icons/activeIcon/coachsIconActice.svg";
import DashboardIconActive from "../../assets/icons/activeIcon/DashboardIconActive.svg";
import {
  XMarkIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = location.pathname.split("-")[0].split("/")[1];

  const { logOut } = useAuth();
  const signout = () => {
    logOut();
    navigate("");
  };

  const programId = localStorage.getItem("programId");

  const links = [
    {
      role: "laureat",
      links: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          iconActive: DashboardIconActive,
          path: "",
        },
        ...(programId
          ? [
            {
              name: "Appel à candidature",
              icon: AppelIcon,
              iconActive: AppelIconActive,
              path: "fill-form",
            },
          ]
          : []),
        {
          name: "Réglages",
          icon: reglageIcon,
          iconActive: reglageIconActive,
          path: "reglages",
        },
      ],
    },
    // {
    //   role: "coach",
    //   links: [
    //     {
    //       name: "Dashboard",
    //       icon: DashboardIcon,
    //       iconActive: DashboardIconActive,
    //       path: "",
    //     },
        // {
        //   name: "Créer formulaire",
        //   icon: AppelIcon,
        //   iconActive: AppelIconActive,
        //   path: "create-form",
        // },
        
        // {
        //   name: "Liste des candidatures",
        //   icon: userListe,
        //   iconActive: coachsIconActice,
        //   path: "candidatures",
        // },
        // {
        //   name: "Liste des fournisseurs",
        //   icon: liste,
        //   iconActive: listFormActive,
        //   path: "fournisseurs",
        // },
    //     {
    //       name: "Réglages",
    //       icon: reglageIcon,
    //       iconActive: reglageIconActive,
    //       path: "reglages",
    //     },
    //   ],
    // },
    {
      role: "unite_de_gestion",
      links: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          iconActive: DashboardIconActive,
          path: "",
        },
        {
          name: "Liste des appels à candidature",
          icon: AppelIcon,
          iconActive: AppelIconActive,
          path: "Appels-à-candidatures",
        },
        {
          name: "Liste des formulaires",
          icon: listForm,
          iconActive: listFormActive,
          path: "formulaires",
        },
       {
          name: "Liste des utilisateurs",
          icon: coachsIcon,
          iconActive: coachsIconActice,
          path: "liste-utilisateurs",
        },
        {
          name: "Liste des candidatures",
          icon: listForm,
          iconActive: listFormActive,
          path: "liste-condidatures",
        },
        {
          name: "Liste des actualités",
          icon: Actualites,
          iconActive: ActualitesActive,
          path: "liste-actualites",
        },
        {
          name: "Réglages",
          icon: reglageIcon,
          iconActive: reglageIconActive,
          path: "reglage",
        },
      ],
    },
    // {
    //   role: "unite_de_coordination_de_projet",
    //   links: [
    //     {
    //       name: "Dashboard",
    //       icon: DashboardIcon,
    //       iconActive: DashboardIconActive,
    //       path: "",
    //     },
    //     {
    //       name: "Liste des candidatures",
    //       icon: AppelIcon,
    //       iconActive: AppelIconActive,
    //       path: "candidatures",
    //     },
    //     {
    //       name: "Réglages",
    //       icon: reglageIcon,
    //       iconActive: reglageIconActive,
    //       path: "reglage",
    //     },
    //   ],
    // },
    // {
    //   role: "consortium",
    //   links: [
    //     {
    //       name: "Dashboard",
    //       icon: DashboardIcon,
    //       iconActive: DashboardIconActive,
    //       path: "",
    //     },
    //     {
    //       name: "Liste des candidatures",
    //       icon: AppelIcon,
    //       iconActive: AppelIconActive,
    //       path: "candidatures",
    //     },
    //     {
    //       name: "Réglages",
    //       icon: reglageIcon,
    //       iconActive: reglageIconActive,
    //       path: "reglage",
    //     },
    //   ],
    // },
    {
      role: "evaluateur",
      links: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          iconActive: DashboardIconActive,
          path: "",
        },
        {
          name: "Liste des candidatures",
          icon: userListe,
          iconActive: coachsIconActice,
          path: "candidatures",
        },
        {
          name: "Réglages",
          icon: reglageIcon,
          iconActive: reglageIconActive,
          path: "reglages",
        },
      ],
    },
    // {
    //   role: "coordinateur",
    //   links: [
    //     {
    //       name: "Dashboard",
    //       icon: DashboardIcon,
    //       iconActive: DashboardIconActive,
    //       path: "",
    //     },
    //     {
    //       name: "Liste des candidatures",
    //       icon: userListe,
    //       iconActive: coachsIconActice,
    //       path: "candidatures",
    //     },
    //     {
    //       name: "Réglages",
    //       icon: reglageIcon,
    //       iconActive: reglageIconActive,
    //       path: "reglages",
    //     },
      // ],
    // },
  ];

  const { activeMenu, setActiveMenu } = useContext(ActiveMenuContext);
  const activeLink = "text-[#EB2630]";
  // const normalLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:bg-light-gray m-2";

  return (
    <div className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 pl-1     border-r-[1px] border-[#F0F2F5]">
      {activeMenu && (
        <>
          <div className="flex justify-center items-center border-b border-[#F0F2F5] h-[89px] pb-4">
            <Link
              to={`/`}
              className="items-center gap-3 mt-4 flex text-xl font-extrabold tracking-tight text-slate-900 mr-3"
            >
              <img alt="" src={logo} width={160} />
            </Link>

            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              style={{ color: "black" }}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
            >
              <XMarkIcon className="h-5 w-5 text-gray-500" />
            </button>
          </div>
          <div className="mt-10">
            {links.map((item) => (
              <div key={item.role}>
                {item.role === role &&
                  item.links.map((link) => (
                    <NavLink
                      to={link.path}
                      key={link.name}
                      end
                      className={({ isActive }) =>
                        `no-underline py-[12px] flex gap-2 my-1 pl-5 transition-all duration-300 ${isActive
                          ? `${activeLink} bg-gradient-to-r from-[#EB263026] from-5% via-[#FFF3F1] via-30% to-white to-100% border-l-[#EB2630] border-l-4 border-[#FFF3F1] animate-bg`
                          : "text-[#030303] hover:bg-gradient-to-r from-[#8e8c8c26] from-5% via-[#f3f2f2] via-30% to-white to-100% hover:border-l-[#777474] border-l-[#fff] border-l-4 border-[#FFF3F1] hover:animate-bg"
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <img
                            src={isActive ? link.iconActive : link.icon}
                            width={20}
                            alt="is active"
                          />
                          <span>{link.name}</span>
                        </>
                      )}
                    </NavLink>
                  ))}
              </div>
            ))}
          </div>
          <div className="absolute bottom-0 ms-5 mb-3">
            <button className="flex my-3 gap-2" onClick={signout}>
              <ArrowRightStartOnRectangleIcon className="w-[20px] text-[#B3B9C4]" />
              Déconnexion
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
