import axiosInstance from "../helpers/axiosIntercepter";


export const evaluateCandidate = async (surveyRespId, evaluatorId, formData) => {
  try {
    const response = await axiosInstance.patch(
      `/survey-response/evaluate/${surveyRespId}/${evaluatorId}`, formData,  
    );
    return response.data;
  } catch (error) {
    console.error("Error updating evaluation status:", error);
    throw new Error("Failed to update candidate status. Please try again later.");
  }
};
