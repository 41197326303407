import React, { useEffect, useState } from "react";
import { Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreatableSelect from "react-select/creatable";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateActualite,
  getActualiteById,
  updateActualiteStatus,
} from "../../../../services/actualites.service";
import { uploadFile } from "../../../../services/uploadFile.service";
import { categorySuggestions } from "../CreateActualite/CategorySuggestion";

const UpdateActualite = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    category: [],
    media: [],
    status: "",
  });

  const [deletedMedia, setDeletedMedia] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchActualiteData = async () => {
      try {
        const response = await getActualiteById(id);
        const categoryData =
          response.categories?.map((cat) => ({
            label: cat,
            value: cat,
          })) || [];

        setInitialValues({
          title: response.title,
          description: response.description,
          category: categoryData,
          media: response.media || [],
          status: response.status || "brouillon",
        });
      } catch (error) {
        console.error("Error fetching actualité:", error);
      }
    };

    fetchActualiteData();
  }, [id]);

  const ensureHttps = (url) => {
    if (!url?.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      // Start with the existing media
      // let mediaArray = [...initialValues.media];
      // Filter out media files that are in the deletedMedia list
      let mediaArray = initialValues.media.filter(
        (file) => !deletedMedia.includes(file.url)
      );

      if (values.media && values.media.length > 0) {
        const newFiles = values.media.filter((file) => !file.url);

        if (newFiles.length > 0) {
          const uploadPromises = newFiles.map(async (file) => {
            const urls = await uploadFile(
              file,
              "news-articles",
              18000,
              "public"
            );
            console.log("Uploaded File URL:", urls[0].url);
            return { name: file.name, url: ensureHttps(urls[0].url) };
          });

          const uploadedMedia = await Promise.all(uploadPromises);
          mediaArray = [...mediaArray, ...uploadedMedia];
        }
      }


      const updatedValues = {
        ...values,
        categories: values.category.map((cat) => cat.value),
        media: mediaArray,
      };
      console.log("Updated Values:", updatedValues);

      await updateActualiteStatus(id, { status: values.status });

      await updateActualite(id, updatedValues);
      toast.success("L'actualité a été mise à jour avec succès.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });

      // toast.success("Le statut a été mis à jour avec succès.", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   theme: "colored",
      //   transition: Bounce,
      // });

      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error during update:", error);
      toast.error(
        "Erreur lors de la mise à jour de l'actualité ou du statut.",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          transition: Bounce,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Modifier l'actualité</h1>
      <p className="text-gray-500 text-sm mb-4">
        Track and manage customer information and activities.
      </p>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          title: Yup.string().required("Le titre est obligatoire"),
          description: Yup.string().required("La description est obligatoire"),
          media: Yup.array().min(1, "Le média est requis"),

          // category: Yup.array().min(1, "Sélectionnez au moins une catégorie"),
        })}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form className="p-8 bg-white shadow-md rounded-lg mt-2">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Titre de l'article
                </label>
                <Field
                  type="text"
                  name="title"
                  className="w-full p-3 border border-gray-400 rounded-md"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Media Upload Section */}
              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Média
                </label>
                <div
                  className="border-dashed border-2 border-gray-300 p-4 text-center rounded-lg"
                  onClick={() =>
                    document.getElementById("media-upload").click()
                  }
                >
                  <p>📄 Télécharger des fichiers</p>
                  <p className="text-sm text-gray-500">
                    .jpeg, .png, .mp4... formats, up to 50MB
                  </p>
                  <Button variant="outlined" color="primary" className="mt-2">
                    parcourir le fichier
                  </Button>
                  <input
                    id="media-upload"
                    type="file"
                    multiple
                    accept=".jpeg, .png, .mp4, .pdf, .doc, .docx"
                    onChange={(e) => {
                      const filesArray = Array.from(e.currentTarget.files);
                      setFieldValue("media", [...values.media, ...filesArray]);
                    }}
                    className="hidden"
                  />
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  {values.media &&
                    values.media.map((file, index) => (
                      <div key={index} className="relative">
                        {file.url ? (
                          file.url.endsWith(".mp4") ? (
                            <video
                              src={file.url}
                              controls
                              className="w-24 h-24 object-cover rounded"
                            />
                          ) : (
                            <img
                              src={file.url}
                              alt={file.name}
                              className="w-24 h-24 object-cover rounded"
                            />
                          )
                        ) : file.type?.startsWith("image") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : file.type?.startsWith("video") ? (
                          <video
                            src={URL.createObjectURL(file)}
                            controls
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : (
                          <p>{file.name}</p>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            const updatedMedia = values.media.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("media", updatedMedia);

                            if (file.url) {
                              setDeletedMedia((prev) => [...prev, file.url]);
                            }
                          }}
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 p-1"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>

                <ErrorMessage
                  name="media"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Description/ Objectif
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={values.description}
                  onChange={(event, editor) =>
                    setFieldValue("description", editor.getData())
                  }
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Catégories
                </label>
                <CreatableSelect
                  isMulti
                  options={categorySuggestions}
                  value={values.category}
                  onChange={(newValue) => setFieldValue("category", newValue)}
                  placeholder="Choisissez ou ajoutez des catégories"
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Statut
                </label>
                <Select
                  style={{ lineHeight: "40px" }}
                  fullWidth
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                >
                  <MenuItem value="brouillon">Brouillon</MenuItem>
                  <MenuItem value="publié">Publié</MenuItem>
                  <MenuItem value="fermé">Fermé</MenuItem>
                </Select>
                <ErrorMessage
                  name="status"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                className="text-[#0976BC] border-[#0976BC] hover:bg-[#f0f0f0] transition-colors duration-300 shadow-none rounded-full px-4 py-2"
                style={{
                  textTransform: "none",
                  color: "#0976BC",
                  borderColor: "#0976BC",
                }}
              >
                Annuler
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
                style={{ textTransform: "none" }}
                disabled={isSubmitting}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Mettre à jour"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default UpdateActualite;
