import React, { useContext, useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, Serializer } from "survey-core";
import "survey-core/i18n/french";
import { sendSurveyResponse } from "../services/surveyResponse.service";
import { getProgramToFill } from "../services/program.service";
import { uploadFile } from "../services/uploadFile.service";
import Stepper from "./Stepper";
import "survey-core/defaultV2.min.css";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import LaureatGetSurveyForm from "../pages/Laureat-Dashboard/LaureatGetSurveyForm";
import { CircularProgress } from "@mui/material";
import ClosedProgramErrorMessage from "../components/program/ClosedProgramErrorMessage";

const LaureatGetProgramSurvey = () => {
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isFinalStep, setIsFinalStep] = useState(false);

  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);
  useEffect(() => {
    Serializer.addProperty("itemvalue", { name: "score:number" });
  }, []);
  useEffect(() => {
    const programId = localStorage.getItem("programId");

    if (!programId || !userId) {
      return;
    }

    const fetchProgramToFill = async () => {
      try {
        const program = await getProgramToFill(programId, userId);

        const formFields =
          program?.form?.fields || program?.program?.form?.fields;

        if (formFields) {
          const parsedSurvey = JSON.parse(formFields);

          const stepsTitles = parsedSurvey.pages.map((page) => ({
            title: page.title,
            name: page.name,
          }));

          stepsTitles.push({ title: "", name: "" });

          const survey = new Model(parsedSurvey);
          survey.applyTheme(PlainLight);
          survey.showPreviewBeforeComplete = "showAnsweredQuestions";
          survey.locale = "fr";

          survey.getAllQuestions().forEach((question) => {
            if (question.getType() === "file") {
              question.storeDataAsText = false;
              question.allowImagesPreview = true;
              question.waitForUpload = true;
              question.allowMultiple = true;
            }
          });
          const storageKey = `surveyData_${programId}_${userId}`;
          const savedData = localStorage.getItem(storageKey);
          if (savedData) {
            survey.data = JSON.parse(savedData);
          } else if (program?.surveyResponse?.status === "Brouillon") {
            const oldResponses = program.surveyResponse.responses;

            if (oldResponses) {
              survey.data = oldResponses;
            }
          }
          survey.onUploadFiles.add(async (_, options) => {
            const uploadedUrls = [];
            for (const file of options.files) {
              const urls = await uploadFile(
                file,
                "laureat/" + programId + "/" + userId,
                18000,
                "private"
              );

              uploadedUrls.push(
                Object.assign({}, file, {
                  name: urls[0].name,
                  fullUrl: urls[0].url,
                  type: file.type,
                  size: file.size,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                  webkitRelativePath: file.webkitRelativePath,
                })
              );
            }
            options.files = uploadedUrls;

            options.callback(
              options.files.map((file) => {
                return {
                  file: file,
                  content: file.fullUrl,
                };
              })
            );
          });

          survey.onValueChanged.add((sender, options) => {
            const surveyData = sender.data;
            localStorage.setItem(storageKey, JSON.stringify(surveyData));
          });

          survey.onCurrentPageChanged?.add((sender) => {
            const newIndex = stepsTitles.findIndex(
              (step) => step.name === sender.currentPage.name
            );
            setIsFinalStep(newIndex + 1 === stepsTitles.length);
            setCurrentStep(newIndex);
          });

          survey.addNavigationItem({
            id: "survey_save_current_page",
            title: "Enregistrer",
            action: async () => {
              const surveyData = survey.data;
              const token = localStorage.getItem("access-token");
              const responseData = {
                programId: parseInt(programId),
                responses: surveyData,
                action: "enregistrer",
                user_id: userId,
              };

              try {
                await sendSurveyResponse(token, responseData);
                localStorage.removeItem(storageKey);
                toast.success(
                  "Vos réponses ont été enregistrées avec succès en tant que brouillon!",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );

                setTimeout(() => {
                  navigate("/laureat-dashboard");
                }, 2000);
              } catch (error) {
                console.error("Error saving the draft:", error);
                toast.error(
                  "Erreur lors de l'enregistrement du brouillon. Veuillez réessayer.",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );
              }
            },
          });

          function calculateMaxScore(questions) {
            let maxScore = 0;

            questions.forEach((question) => {
              // For radio buttons or checkboxes (choices)
              if (question.choices) {
                if (
                  question.getType() === "checkbox" ||
                  question.getType() === "tagbox"
                ) {
                  // For checkboxes, sum the scores of all choices
                  const totalCheckboxScore = question.choices.reduce(
                    (sum, choice) => sum + (choice.score || 0),
                    0
                  );

                  maxScore += totalCheckboxScore;
                } else {
                  // For radio buttons, get only the highest score
                  const maxValue = Math.max.apply(
                    Math,
                    question.choices.map((choice) => choice.score || 0)
                  );

                  maxScore += maxValue;
                }
              }

              // For rating scale questions (rateValues)
              if (question.rateValues) {
                const maxValue = Math.max.apply(
                  Math,
                  question.rateValues.map((rate) => rate.score || 0)
                );

                maxScore += maxValue;
              }

              // For matrix questions
              if (question.getType() === "matrix") {
                const maxMatrixValue =
                  Math.max.apply(
                    Math,
                    question.columns.map((column) => column.score || 0)
                  ) * question.rows.length;
                maxScore += maxMatrixValue;
              }
            });

            return maxScore;
          }
          function calculateTotalScore(data) {
            let totalScore = 0;

            data.forEach((item) => {
              const question = survey.getQuestionByValueName(item.name);
              const qValue = item.value;

              // For questions with choices (radio buttons or checkboxes)
              if (question?.choices) {
                if (Array.isArray(qValue)) {
                  // Checkbox (multiple selections)
                  qValue.forEach((selectedValue) => {
                    const selectedChoice = question.choices.find(
                      (choice) => choice.value === selectedValue
                    );
                    if (selectedChoice) {
                      totalScore += selectedChoice.score || 0;
                    }
                  });
                } else {
                  // Radio button (single selection)
                  const selectedChoice = question.choices.find(
                    (choice) => choice.value === qValue
                  );
                  if (selectedChoice) {
                    totalScore += selectedChoice.score || 0;
                  }
                }
              }

              // For rating scale questions
              if (question?.rateValues) {
                const selectedRate = question.rateValues.find(
                  (rate) => rate.value === qValue
                );
                if (selectedRate) {
  
                  totalScore += selectedRate.score || 0;
                }
              }

              // For matrix questions
              if (question.getType() === "matrix" && item.data) {
                item.data.forEach((dataItem) => {
                  if (dataItem?.value && dataItem.score) {
                    totalScore += dataItem.score;
                  }
                });
              }
            });

            return totalScore;
          }

          survey.onComplete?.add(async (sender) => {
            // const totalScore = sender.getValue("totalScore");
            const maxScore = calculateMaxScore(sender?.getAllQuestions());
            const plainData = sender?.getPlainData({
              calculations: [{ propertyName: "score" }],
            });
            const totalScore = calculateTotalScore(plainData);

            sender.setValue("maxScore", maxScore);
            sender.setValue("totalScore", totalScore);
            const surveyData = sender.data;

            const token = localStorage.getItem("access-token");

            const responseData = {
              programId: parseInt(programId),
              responses: surveyData,
              user_id: userId,
              note: totalScore,
              totalNote: maxScore,
              action: "envoyer",
            };

            try {
              await sendSurveyResponse(token, responseData);
              localStorage.removeItem(storageKey);
              toast.success("Vos réponses ont été envoyées avec succès!", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
              });

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            } catch (error) {
              console.error("Error sending final response:", error);
              toast.error(
                "Erreur lors de l'envoi des réponses. Veuillez réessayer.",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );
            }
          });

          setSteps(stepsTitles);
          setSurveyModel(survey);
        } else {
          setErrorData("No survey data found for this program.");
        }
      } catch (err) {
        // console.error("Failed to fetch the program to fill. Please try again later.", err);
        // setErrorData("Failed to fetch the program to fill. Please try again later.");

        console.error("Error caught in LaureatGetProgramSurvey:", err.message);

        if (err.message.includes("Fermé") || err.message.includes("closed")) {
          setErrorData("closedProgram");
        } else {
          setErrorData(
            "Failed to fetch the program to fill. Please try again later."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProgramToFill();
  }, [userId]);

  if (loading) return <CircularProgress size={24} />;

  if (errorData) {

    return (
      <div>
        {errorData === "closedProgram" ? (
          <ClosedProgramErrorMessage />
        ) : (
          <LaureatGetSurveyForm />
        )}
      </div>
    );
  }

  return (
    <div className="App">
      {steps.length > 0 && (
        <Stepper
          steps={steps}
          currentStep={isFinalStep ? steps.length : currentStep}
        />
      )}
      <React.Fragment>
        {surveyModel ? (
          <React.Fragment>
            <Survey model={surveyModel} key="surveyComponent" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LaureatGetSurveyForm />
          </React.Fragment>
        )}
      </React.Fragment>

      <ToastContainer />
    </div>
  );
};

export default LaureatGetProgramSurvey;
