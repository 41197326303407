import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { BsFileEarmarkArrowDown, BsPlusSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import * as XLSX from "xlsx";
import { getAllSurveyResponses } from "../../../services/surveyResponse.service";
import { getProgramById } from "../../../services/program.service";
import GetStatusLaureat from "../../../helpers/getStatusLaureat";
// import { FaSortUp, FaSortDown } from "react-icons/fa";
import axiosInstance from "../../../helpers/axiosIntercepter";
import assignCoachIcon from "../../../assets/assignCoachIcon.png"

const ListeCandidatures = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [formByProgramId, setFormByProgramId] = useState(null);
  const [percentage, setPercentage] = useState(null);
  // const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  const statusOptions = [
    { value: "", label: "Tous les statuts" },
    { value: "Brouillon", label: "Brouillon" },
    { value: "SOUMIS", label: "Soumis" },
    { value: "Sélectionné", label: "Sélectionné" },
    { value: "Rejeté", label: "Rejeté" },
  ];

  const fetchCandidatures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllSurveyResponses({
        page: page + 1,
        limit: rowsPerPage,
        searchTerm,
        status,
      });
      setCandidatures(response.data);
      setTotalCount(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm, status]);

  useEffect(() => {
    fetchCandidatures();
  }, [fetchCandidatures]);

  useEffect(() => {
    if (candidatures.length > 0) {
      const getProgramAndParseFields = async () => {
        try {
          const programDataPromises = candidatures
            .map((candidature) => candidature?.program?.id)
            .filter(Boolean)
            .map(getProgramById);

          const programDataArray = await Promise.all(programDataPromises);
          setFormByProgramId(programDataArray);

          const laureatResponsesArray = candidatures.map(
            (candidature) => candidature?.responses || {}
          );
          const fieldsArray = programDataArray.map((program) => {
            const form = program?.form;
            if (form && form.fields) {
              try {
                return JSON.parse(form.fields).pages || [];
              } catch (error) {
                console.error("Error parsing fields:", error);
                return [];
              }
            }
            return [];
          });

          const completionPercentages = laureatResponsesArray.map(
            (laureatResponses, index) => {
              const fields = fieldsArray[index];
              const totalFields = fields.reduce(
                (acc, page) => acc + (page.elements?.length || 0),
                0
              );
              const filledResponses = Object.keys(
                laureatResponses || {}
              ).filter(
                (key) =>
                  laureatResponses[key] !== null && laureatResponses[key] !== ""
              ).length;
              return totalFields > 0
                ? Math.min((filledResponses / totalFields) * 100, 100)
                : 0;
            }
          );

          setPercentage(completionPercentages);
        } catch (error) {
          console.error("Error fetching programs or parsing fields:", error);
        }
      };
      getProgramAndParseFields();
    }
  }, [candidatures]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    setSelected(
      event.target.checked
        ? candidatures.map((candidature) => candidature.id)
        : []
    );
  };

  const handleClick = (event, id, candidature) => {
    // if (candidature?.keycloakCoachId !== null) return;

    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => debouncedSearch(event.target.value);

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const handleExportClick = async () => {
    const candidatesWithUpdatedFiles = await Promise.all(
      candidatures.map(async (candidature) => {
        return {
          ...candidature,
          responses: await updatedFilesDate(candidature.responses),
        };
      })
    );

    const wsData = candidatesWithUpdatedFiles.map((candidature, index) => {
      let fileUrls = [];

      if (candidature.responses) {
        Object.values(candidature.responses).forEach((response) => {
          if (Array.isArray(response)) {
            response.forEach((file) => {
              if (file.name) {
                const fullUrl = file.content;
                fileUrls.push(fullUrl);
              }
            });
          }
        });
      }

      return {
        "Nom du candidat": `${candidature?.user?.firstName || ""} ${
          candidature?.user?.lastName || ""
        }`,
        "Adresse mail": `${candidature?.user?.email || ""}`,
        "Appel à candidature": candidature?.program?.title || "",
        "Date de soumission": new Date(
          candidature?.updatedAt
        ).toLocaleDateString("fr-FR"),
        État:
          percentage && percentage[index] !== undefined
            ? `${percentage[index].toFixed(2)}%`
            : "0%",
        Statut: candidature?.status || "",
        "Fichiers soumis":
          fileUrls.length > 0 ? fileUrls.join(", ") : "Aucun fichier",
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(wsData);

    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" }, sz: 12 },
      fill: { fgColor: { rgb: "0070C0" } }, // Blue background
      alignment: { horizontal: "center", vertical: "center" },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      },
    };

    const dataStyle = {
      font: { color: { rgb: "000000" }, sz: 11 },
      alignment: { horizontal: "left", vertical: "center" },
      border: {
        top: { style: "thin", color: { rgb: "D3D3D3" } },
        bottom: { style: "thin", color: { rgb: "D3D3D3" } },
        left: { style: "thin", color: { rgb: "D3D3D3" } },
        right: { style: "thin", color: { rgb: "D3D3D3" } },
      },
    };

    Object.keys(ws).forEach((key) => {
      if (key.includes("1")) {
        ws[key].s = headerStyle;
      }
    });

    for (let row = 2; row <= candidatures.length + 1; row++) {
      ["A", "B", "C", "D", "E"].forEach((col) => {
        const cellRef = `${col}${row}`;
        if (ws[cellRef]) {
          ws[cellRef].s = dataStyle;
        }
      });
    }

    ws["!cols"] = [
      { wch: 25 }, // Nom du candidat
      { wch: 20 }, // Adresse mail
      { wch: 30 }, // Appel à candidature
      { wch: 20 }, // Date de soumission
      { wch: 10 }, // État
      { wch: 20 }, // Statut
      { wch: 50 }, // Fichiers soumis
    ];

    XLSX.utils.book_append_sheet(wb, ws, "Liste des Candidatures");
    XLSX.writeFile(wb, "Liste_des_Candidatures.xlsx");
  };

  // const handleSortByPercentage = () => {
  //   const newOrder = sortOrder === "asc" ? "desc" : "asc";
  //   setSortOrder(newOrder);
  //   const sortedCandidatures = [...candidatures]?.sort((a, b) => {
  //     const aIndex = candidatures?.indexOf(a);
  //     const bIndex = candidatures?.indexOf(b);
  //     const aPercentage = percentage[aIndex] || 0;
  //     const bPercentage = percentage[bIndex] || 0;

  //     return newOrder === "asc" ? aPercentage - bPercentage : bPercentage - aPercentage;
  //   });
  //   setCandidatures(sortedCandidatures);
  // };

  // const handleDetailsClick = (id) => {
  //   navigate(`/unite_de_gestion-dashboard/liste-condidatures/details/${id}`);
  // };

  const handleAssignCoachClick = () => {
    if (selected.length > 0) {
      const queryParams = selected.map((id) => `ids=${id}`).join("&");
      navigate(`assign-coach?${queryParams}`);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
      <p className="text-gray-500 text-sm mb-4">
        Suivez le statut des candidatures.
      </p>
{/* 
      <div className="flex justify-end mb-4">
        <Button
          style={{
            textTransform: "none",
            backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
            color: selected.length > 0 ? "white" : "#666",
            cursor: selected.length > 0 ? "pointer" : "not-allowed",
          }}
          className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
            selected.length > 0
              ? "hover:bg-[#065A94] transition-colors duration-300"
              : ""
          }`}
          disabled={selected.length === 0}
          onClick={handleAssignCoachClick}
        >
          <img src={assignCoachIcon} alt="assignCoachIcon" width={20} height={20} />
          Assigner un coach
        </Button>
      </div> */}

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, mail..."
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex gap-[16px] ">
            <FormControl
              variant="outlined"
              sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
            >
              <Select
                value={status}
                onChange={handleStatusChange}
                displayEmpty
                style={{ "height ": "45px" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 text-gray mr-3" />
                )}
                renderValue={(selected) => {
                  const selectedOption = statusOptions.find(
                    (option) => option.value === selected
                  );
                  return selectedOption ? selectedOption.label : "Status";
                }}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  fontFamily: "Arial, sans-serif",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              style={{
                textTransform: "none",
                fontFamily: "Arial, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm"
              onClick={handleExportClick}
            >
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button>
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selected.length > 0 &&
                      selected.length === candidatures.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Nom du candidat</TableCell>
                <TableCell>Adresse mail</TableCell>
                <TableCell>Appel à candidature</TableCell>
                <TableCell>Date de soumission</TableCell>
                <TableCell>
                  {" "}
                  {/* onClick={handleSortByPercentage} style={{ cursor: 'pointer' }} */}
                  État
                  {/* {sortOrder === "asc" ? (
                    <FaSortUp className="inline-block ml-1" />
                  ) : (
                    <FaSortDown className="inline-block ml-1" />
                  )} */}
                </TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : candidatures.length > 0 ? (
                candidatures.map((candidature, index) => (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, candidature.id, candidature)
                    }
                    role="checkbox"
                    aria-checked={isSelected(candidature.id)}
                    tabIndex={-1}
                    key={candidature.id}
                    selected={isSelected(candidature.id)}
                  >
                    <TableCell padding="checkbox">
                      {/* {candidature.status === "Sélectionné" ? ( */}
                        <Checkbox
                        // checked={
                        //   isSelected(candidature.id) || !!candidature?.keycloakCoachId
                        // }
                          // onChange={(event) =>
                          //   handleClick(event, candidature.id, candidature)
                          // }
                        />
                      {/* ) : null} */}
                    </TableCell>
                    <TableCell>
                      {candidature?.user?.firstName}{" "}
                      {candidature?.user?.lastName}
                    </TableCell>
                    <TableCell>{candidature?.user?.email}</TableCell>
                    <TableCell>{candidature.program?.title}</TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        <span>
                          {new Date(candidature?.updatedAt).toLocaleDateString(
                            "fr-FR"
                          )}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {percentage && percentage[index] !== undefined ? (
                        <span>{percentage[index].toFixed(2)}%</span>
                      ) : (
                        <span>0%</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <GetStatusLaureat status={candidature.status} />
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/liste-condidatures/details/${candidature.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                        style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                      >
                        Voir détails
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Aucune donnée affichée
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </div>
    </div>
  );
};

export default ListeCandidatures;
