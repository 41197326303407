export const categorySuggestions = [
    { label: "Innovation", value: "Innovation" },
    { label: "Entrepreneuriat", value: "Entrepreneuriat" },
    { label: "Transformation Digitale", value: "Transformation Digitale" },
    { label: "Technologie", value: "Technologie" },
    { label: "Leadership", value: "Leadership" },
    { label: "Stratégie d'Entreprise", value: "Stratégie d'Entreprise" },
    { label: "Investissement", value: "Investissement" },
    { label: "Startups", value: "Startups" },
    { label: "E-commerce", value: "E-commerce" },
    { label: "Marketing Digital", value: "Marketing Digital" },
    { label: "Gestion de Projet", value: "Gestion de Projet" },
    { label: "Réseaux Sociaux", value: "Réseaux Sociaux" },
    { label: "Croissance Durable", value: "Croissance Durable" },
    { label: "Automatisation", value: "Automatisation" },
    { label: "Intelligence Artificielle", value: "Intelligence Artificielle" },
    { label: "Blockchain", value: "Blockchain" },
    { label: "Fintech", value: "Fintech" },
    { label: "Gestion des Talents", value: "Gestion des Talents" },
    { label: "Expérience Client", value: "Expérience Client" },
    { label: "Management de l'Innovation", value: "Management de l'Innovation" },
    { label: "Économie Numérique", value: "Économie Numérique" },
    { label: "Financement", value: "Financement" },
    { label: "Stratégie Commerciale", value: "Stratégie Commerciale" },
    { label: "Transformation Agile", value: "Transformation Agile" },
    { label: "Partenariats Stratégiques", value: "Partenariats Stratégiques" },
    { label: "Technologie Cloud", value: "Technologie Cloud" },
    { label: "Développement Durable", value: "Développement Durable" },
    { label: "Cyber-sécurité", value: "Cyber-sécurité" },
    { label: "Analyse de Données", value: "Analyse de Données" },
    { label: "Commerce International", value: "Commerce International" },
    { label: "Analyse", value: "Analyse" },
    { label: "Marketing", value: "Marketing" },
    { label: "Stratégie", value: "Stratégie" },
    { label: "Gestion d'entreprise", value: "Gestion d'entreprise" },
    { label: "Entrepreneuriat", value: "Entrepreneuriat" },
    { label: "Finance", value: "Finance" },
    { label: "Investissement", value: "Investissement" },
    { label: "Transformation numérique", value: "Transformation numérique" },
    { label: "Leadership", value: "Leadership" },
    { label: "Gestion de projet", value: "Gestion de projet" },
    { label: "Ressources humaines", value: "Ressources humaines" },
    { label: "Formation", value: "Formation" },
    { label: "E-commerce", value: "E-commerce" },
    { label: "Blockchain", value: "Blockchain" },
    { label: "Intelligence artificielle", value: "Intelligence artificielle" },
    { label: "Big Data", value: "Big Data" },
    { label: "Automatisation", value: "Automatisation" },
    { label: "Startups", value: "Startups" },
    { label: "Réseaux sociaux", value: "Réseaux sociaux" },
    { label: "Économie", value: "Économie" },
    { label: "Communication", value: "Communication" },
    { label: "Commerce international", value: "Commerce international" },
    { label: "Technologies émergentes", value: "Technologies émergentes" },
    { label: "Cybersécurité", value: "Cybersécurité" },
    { label: "Partenariats", value: "Partenariats" },
    { label: "Innovation sociale", value: "Innovation sociale" },
    { label: "Industrie 4.0", value: "Industrie 4.0" },
    { label: "Mobilité", value: "Mobilité" },
    { label: "Cloud computing", value: "Cloud computing" },
    { label: "Data science", value: "Data science" },
    {
      label: "Transformation organisationnelle",
      value: "Transformation organisationnelle",
    },
  ];