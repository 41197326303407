import axios from "axios";
import config from "../assets/environments/config.json";
import { refreshTokenService } from "../services/auth.service";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

// Request interceptor to add authorization token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration and refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an unauthorized (401) status
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried
      const refreshToken = localStorage.getItem('refresh-token'); // Get the refresh token from local storage

      if (refreshToken) {
        try {
          // Use the refresh token to get a new access token
          const response = await refreshTokenService(refreshToken);
          const newAccessToken = response.data.access_token; // Adjust based on your API response

          localStorage.setItem('access-token', newAccessToken); // Save the new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`; // Update the original request with the new token

          return axios(originalRequest); // Retry the original request with the new token
        } catch (err) {
          console.error("Failed to refresh token:", err);
          // Call the logOut function to log the user out
          // Assuming you have a way to get the logOut function here
          // You can implement a way to handle this logic in a suitable way.
          logOut(); // You need to define logOut function properly
        }
      }
    }

    return Promise.reject(error); // Reject the promise with the original error
  }
);

// Log out function needs to be defined in a way that is accessible here
const logOut = () => {
  // Implement your logout logic here, such as clearing tokens and redirecting the user
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
  window.location.href = ""; // Redirect to the login page
};

export default axiosInstance;
