import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../contexts/AuthContext";
import { evaluateCandidate } from "../../../../services/evaluation.service";

const EvaluationProcess = ({ arrayOfActions, id, status }) => {
  const [evaluatorComm, setEvaluatorComm] = useState();
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingReject, setIsSubmittingReject] = useState(false);

  const evaluateursFeedback = arrayOfActions?.filter(
    (item) => !item?.isSystemEvaluation
  );

  const currentUser = evaluateursFeedback?.filter(
    (item) => item.evaluatorKeyCloakId === userId
  );

  const handleComment = (e) => {
    setEvaluatorComm(e.target.value);
  };


  const addEvaluationStatusRejection = async () => {
    setIsSubmittingReject(true);
    try {
      const response = await evaluateCandidate(id, userId, {
        status: "Rejeté",
        evaluatorComment: evaluatorComm,
      });
      console.log(response);
      toast.success("Candidature rejetée avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => navigate(-1), // Redirect after toast is closed
      });
    } catch (error) {
      console.log("error in rejecting the candidature");
      toast.error("Vous avez déjà soumis votre réponse d'évaluation.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setIsSubmittingReject(false);
    }
  };

  const addEvaluationStatusEligible = async () => {
    setIsSubmitting(true);
    try {
      const response = await evaluateCandidate(id, userId, {
        status: "Éligible",
        evaluatorComment: evaluatorComm,
      });
      toast.success("Candidature validée avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => navigate(-1),
      });
    } catch (error) {
      console.log("error in validating the candidature ");
      toast.error("Vous avez déjà soumis votre réponse d'évaluation.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="flex gap-2 flex-col">
        {evaluateursFeedback &&
          evaluateursFeedback?.length > 0 &&
          evaluateursFeedback?.map((evaliateurFeedback, index) => {
            return (
              <div className="flex flex-col border-2 border-[#E2F1FA]  rounded-lg p-1">
                <h5>Evaluation n° {index + 1}:</h5>
                <p>
                  <span className="font-semibold">Evaluateur Id:</span>{" "}
                  {evaliateurFeedback?.evaluatorKeyCloakId}
                </p>
                <p className="">
                  <span className="font-semibold"> Status :</span>{" "}
                  <span
                    className={` ${
                      evaliateurFeedback?.status === "Rejeté"
                        ? "text-[#EE5D50]"
                        : "text-[#05CD99]"
                    } `}
                  >
                    {evaliateurFeedback?.status}
                  </span>
                </p>
                <p>
                  <span className="font-semibold">Commentaire : </span>
                  {evaliateurFeedback?.evaluatorComment}
                </p>
              </div>
            );
          })}
      </div>

      {status === "Rejeté" ||
      evaluateursFeedback?.length === 2 ||
      (currentUser && currentUser[0]?.evaluatorKeyCloakId === userId) ? (
        " "
      ) : (
        <>
          <div className="flex flex-col justify-start p-4 gap-2 bg-[#E2F1FA] my-2">
            <div className="flex flex-col">
              <label
                for="myTextarea"
                className="font-semibold text-[#0976BC] text-[22px] mb-2"
              >
                Veuillez ajouter votre commentaire:
              </label>
              <textarea
                id="myTextarea"
                rows="4"
                cols="50"
                placeholder="Notes de l'évaluateur"
                className="p-2"
                onChange={handleComment}
              ></textarea>
            </div>
          </div>

          <div className="flex gap-2 justify-end">
            <button
              className="text-[#0976BC] flex gap-2 justify-center items-center border border-[#0976BC96] rounded-[20px] py-2 px-4"
              onClick={addEvaluationStatusRejection}
            >
              {isSubmittingReject ? (
                <div className="h-5 w-5 border-t-2 border-b-2 border-[#EE5D50] rounded-full animate-spin"></div>
              ) : (
                <XCircleIcon className="h-5 w-5 text-[#EE5D50]" />
              )}
              Rejeter
            </button>
            <button
              className="text-[#0976BC] flex gap-2 justify-center items-center border border-[#0976BC96] rounded-[20px] py-2 px-4"
              onClick={addEvaluationStatusEligible}
            >
              {isSubmitting ? (
                <div className="h-5 w-5 border-t-2 border-b-2 border-[#05CD99] rounded-full animate-spin"></div>
              ) : (
                <CheckCircleIcon className="h-5 w-5 text-[#05CD99]" />
              )}
              Valider
            </button>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default EvaluationProcess;