import axiosInstance from '../helpers/axiosIntercepter';

export const laureatStatistics = async (keycloakUserId, keycloakCoachId, programId) => {
  try {
    
    const response = await axiosInstance.get(
      "/survey-response/statistics/all",
      {
        params: {
          keycloakUserId,
          keycloakCoachId,
          programId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching laureat statistics:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch laureat statistics. Please try again later.');
  }
};
